.get-started-modal{
    width: 600px;
}
.get-started-modal .ant-modal-content{
    background-color: #E6EAF3;
    padding: 0px 25px 20px
}


/* .get-started-modal .ant-col .ant-row{
    margin-bottom: 0px;
}

.get-started-modal .input-item{
    width: 100%
}
.get-started-modal .react-tel-input .form-control{
    width: 100%;
    height: 30px
}
.get-started-modal .react-tel-input .country-list{
    position: fixed !important;
}
.get-started-modal .errMsg{
    width: 100%;
}
.get-started-modal .react-tel-input .country-list .search-box{
    width: 90%;
} */
