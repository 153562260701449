.get-started-component .ant-col .enterNumMsg {
    color: RGBA(232, 130, 71, 1);
    font-weight: bold;
}

.get-started-component .ant-col-rtl .enterNumMsg {
    color: #000;
}

.get-started-component {
    margin-top: 30px
}

.get-started-component .react-tel-input {
    direction: ltr;
}

.get-started-component .get-started-butt.ant-btn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    font-size: 18px;
    font-weight: 700;
    border-radius: 4px;
    outline: none;
    border: none;
    background: #3bc83f;
    background: linear-gradient(180deg, #3bc83f 0, #1aaf53);
    color: #fff;
    font-weight: bold;
    height: 50px;
}

.get-started-component img {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    margin-top: 25px;
}

.input-item {
    width: 100%;
    overflow: hidden;
}

.form-control {
    width: 100%;
    height: 50px;
    border: none;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.react-tel-input .special-label{
    display: none !important;
}

@media only screen and (max-width: 420px) {
    .get-started-component .ant-col .enterNumMsg {
        color: #000;
        text-align: left;
    }
}
