.app-header{
    background-color:#fff !important;
    height: 64px;
    overflow: hidden;
    z-index: 999;
}
.app-header .container{
    max-width: 90%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
}
.app-header .logo {
    width: 250px;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-header .logo img{
    height: 70%;
    width: 100%;
    object-fit: contain;
}
.app-header .ant-menu-item{
    font-family: 'PT Sans';
    font-size: 18px;
    font-weight: bold;
    /* color: var(--secondary); */
}
.app-header .ant-menu-item .ant-select-selector{
    border: 1px solid var(--secondary);
    border-radius: 4px;
    padding: 0 15px;
    color: var(--secondary);
    font-weight: bold;
}

.app-header .ant-btn {
    font-weight: bold;
    color: #fff !important;
}

@media only screen and (max-width: 1024px) {
    .app-header {
        padding: 0 !important
    }
    .app-header .logo img{
        object-position: center left;
    }
}

@media only screen and (max-width: 420px) {
    .app-header .hide-mobile{
       display: none !important;
    }
    .app-header .ant-menu-item{
        padding-right: 0 !important;
    }
}