.app-footer{
    background-color:#fff !important;
    overflow: hidden;
    z-index: 999;
}
.app-footer .container {
    max-width: 90%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px auto;
    position: relative;
    background-color: #fff;
}
.app-footer .logo {
    width: 250px;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.app-footer .logo img {
    height: 70%;
    width: 100%;
    max-width: 250px;
    object-fit: contain;
}
.app-footer .logo p{
    margin: 15px auto 0;
    font-size: 14px;
    font-weight: 500
}
.app-footer .content{
    text-align: right;
}
.app-footer .content h3{
    font-size:22px;
    font-weight: bold
}
.app-footer .content p{
    margin-bottom: 5px
}
.app-footer .content p span{
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    letter-spacing: 0px;
}
.app-footer .content.ant-col-rtl p span{
    margin-left: 10px;
    margin-right: 0px;
}
.app-footer .content .ant-tag{
    padding: 3px 10px;
    font-size:14px;
    margin-right: 2px;
    letter-spacing: 0px;
}

.app-footer .content.ant-col-rtl p span.ant-tag{
    direction: ltr;
} 

.app-footer .footer-icon-social span{
    padding: 0px 5px;
}

.app-footer .footer-icon-social span svg{
    font-size: 20px;
}

@media only screen and (max-width: 1024px) {
    .app-footer {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .app-footer .logo img {
        object-position: center left;
    }
}

@media only screen and (max-width: 420px) {
    .app-footer {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }
    .app-footer .content h3 {
        font-size: 18px;
    }
    .app-footer .content p {
        margin-bottom: 5px
    }
    .app-footer .content p span {
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
        letter-spacing: 0px;
        margin-bottom: 10px;
    }
    .app-footer .content .ant-tag {
        padding: 3px 10px;
        font-size: 12px;
        margin-right: 2px;
        letter-spacing: 0px;
        margin-bottom: 8px;
    }
    .app-footer .ant-row-rtl{
        flex-direction: row-reverse;
    }
}