@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --secondary: #E88247;
  --primary: #fff;

  --background: #f6f9ff;
  --foreground: #ffffff;
}

* {
  font-family: 'Open Sans',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'PT Sans',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}
h1{
  font-size: 42px;
  font-weight: 700 !important;
  line-height: 48px;
}
h2 {
  font-size: 32px;
  font-weight: 600 !important;
  line-height: 38px;
}
p{
  font-size:18px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
