.home-page {
    display: flex;
    flex-direction: row;
    /* height: calc(100vh - 64px); */
    background-color: RGBA(230, 234, 243, 1.00);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    overflow-x: hidden;
}

.home-page img.bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    object-fit: contain;
    object-position: top right;
    z-index: 1;
}

.home-page .ant-row {
    width: 100%;
    z-index: 9;
}

.input-item {
    width: 100%;
    overflow: hidden;
}
.form-control {
    width: 100%;
    height: 50px;
    border: none;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}


.home-page .section {
    max-width: 80%;
    z-index: 9;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0 auto;
}

.home-page .section>.ant-row {
    z-index: 99;
}

.home-page .section.full-width {
    max-width: 100%;
    overflow: hidden;
}

.home-page .section img.phone-mockup {
    height: 80vh;
    object-fit: contain;
    object-position: bottom;
    margin: 30px;
    width: 100%
}
.home-page .section img.phone-mockup.ipad {
    width: auto
}

.home-page .section.full-width img.bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;
    z-index: 0;
}

/* .home-page .get-started h1{
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
} */
/* .home-page .get-started .get-started-component .ant-col .enterNumMsg{
    color: RGBA(232, 130, 71, 1);
    font-weight: bold;
}
.home-page .get-started .get-started-component .ant-col-rtl .enterNumMsg{
    color: #000;
}
.home-page .get-started .get-started-component{
    margin-top: 30px
}
.home-page .get-started .get-started-component .react-tel-input{
    direction: ltr;
}
.home-page .get-started .get-started-component .get-started-butt.ant-btn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    font-size: 18px;
    font-weight: 700;
    border-radius: 4px;
    outline: none;
    border: none;
    background: #3bc83f;
    background: linear-gradient(180deg, #3bc83f 0, #1aaf53);
    color: #fff;
    font-weight: bold;
    height: 50px;
}
.get-started-component img{
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    margin-top: 25px;
} */

.home-page .testimonial_1 {
    height: 80vh !important;
}

.home-page .testimonial_1 .ant-card {
    margin-bottom: 50px;
    padding: 25px;
    text-align: left;
    border-radius: 5px;
}

.home-page .section.use-cases {
    margin: 100px auto 0px;
}

.home-page .section.use-cases .feature-card{
    border-radius: 8px;
    margin-top: 30px;
}
.home-page .section.use-cases .feature-card .ant-avatar{
    margin-right: 10px;
    overflow: visible
}
.home-page .section.use-cases.ant-row-rtl .feature-card .ant-avatar{
    margin-left: 10px;
    margin-right: 0px;
}
.home-page .section.use-cases .feature-card .ant-avatar img{
    color: RGBA(226, 115, 32, 1.00);
}
.home-page .section.use-cases .feature-card h3{
    color: RGBA(226, 115, 32, 1.00);
}
.home-page .section.use-cases .feature-card p{
    font-size: 16px;
    margin: 0
}
.home-page .section.use-cases .feature-card .ant-tag{
    padding: 2px 10px;
    margin-top: 10px;
    font-weight: bold;
}


.home-page .section.reverse {
    flex-direction: row-reverse;
}

.home-page .phone-circle {
    border-radius: 50%;
    background-color: RGBA(232, 130, 71, 0.13);
    background: linear-gradient(135deg, #f19f5f 30%, #e06631 100%);
    width: 420px;
    height: 420px;
    overflow: hidden;
    padding: 50px 20px 0 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-shadow: inset 2px 3px 35px -2px rgba(0, 0, 0, 0.3);
}

.home-page .phone-circle img.phone-mockup {
    height: 130%;
    margin: 0
}

.home-page .testimonial_2 {
    margin-top: 100px;
    background: linear-gradient(135deg, #f19f5f 30%, #e06631 100%);
}

.home-page .testimonial_2 .slide-container {
    background: transparent;
    display: flex !important;
    justify-content: center;
    padding: 50px 0 80px;
}

.home-page .testimonial_2 .ant-card {
    max-width: 80%;
    padding: 36px
}

.home-page .testimonial_2 .ant-card img {
    position: relative;
    width: 100%;
    height: 360px;
    object-fit: cover;
    border-radius: 8px;
}



@media only screen and (max-width: 1024px){
    .home-page .section{
        max-width: 90%;
    }
    .home-page .testimonial_1 img.phone-mockup{
        max-height: 600px;
    }
    .home-page .section.full-width{
        /* max-height: 600px; */
    }

    .home-page .phone-circle{
        width: 360px;
        height: 360px;
    }
}

@media only screen and (max-width: 800px) {
    .home-page .section {
        max-width: 90%;
        /* text-align: center; */
    }
    .home-page .section.get-started {
        padding: 30px 0;
    }
    .home-page img.bg{
        width: 50%;
        bottom: 0;
        top: 0;
        right: 0;
    }
    .home-page .section img.phone-mockup {
        max-height: 400px;
        margin: 0;
        text-align: center;
    }
    .home-page .testimonial_1 {
        /* height: 50vh !important; */
        max-height: 540px !important;
    }
    .home-page .testimonial_1 img.bg{
        object-position: center top !important;
    }
    .home-page .testimonial_1 .ant-card{
        margin-bottom: 36px;
        padding: 10px
    }
    .home-page .section.use-cases {
        margin: 50px auto 0px;
    }
    .home-page .phone-circle {
        width: 300px;
        height: 300px;
        margin: 40px auto 0;
    }

    .home-page .testimonial_2{
        max-height: 650px !important;
    }
    .home-page .testimonial_2 .slide-container{
        padding-top:30px;
        padding-bottom: 56px;
    }
    .home-page .testimonial_2 .ant-card {
        max-width: 90%;
        padding: 0;
    }
    .home-page .testimonial_2 .ant-card img{
        height: 200px;
        margin-bottom: 20px
    }

    h2{
        font-size: 26px;
        line-height: 32px
    }
    p{
        font-size: 16px;
    }
}

@media only screen and (max-width: 420px) {

    .home-page .section {
        text-align: center;
    }
    .home-page img.bg {
        width: 180%;
        bottom: 0;
        top: 0;
        height: 100%;
    }
    .home-page .section.get-started{
        padding-top: 50px;
        padding-bottom: 0;
        text-align: left;
    }
    .home-page .section img.phone-mockup{
        max-height: 360px;
        margin: 0;
        text-align: center;
        object-fit: cover;
        object-position: center top;
    }
    .home-page .testimonial_1 {
        max-height: 500px !important;
    }
    .home-page .testimonial_1 .ant-card {
        margin-bottom: 20px;
        padding: 5px
    }
    .home-page .phone-circle{
        padding-top: 25px;
    }

    .home-page .testimonial_2 .slide-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    /* .home-page .section {
        max-width: 90%;
        text-align: center;
    }

    .home-page .section.get-started {
        padding: 30px 0;
    }

    .home-page img.bg {
        width: 100%;
        bottom: 0;
        top: 0;
    }

    .home-page .section img.phone-mockup {
        max-height: 400px;
        margin: 0;
        text-align: center;
    }

    .home-page .testimonial_1 {
        height: 50vh !important;
        max-height: 540px !important;
    }

    .home-page .testimonial_1 img.bg {
        object-position: center top !important;
    }

    .home-page .testimonial_1 .ant-card {
        margin-bottom: 20px;
    }

    .home-page .section.use-cases {
        margin: 50px auto 0px;
    }

    .home-page .phone-circle {
        width: 300px;
        height: 300px;
        margin: 40px auto 0;
    }

    .home-page .testimonial_2 {
        max-height: 650px !important;
    }

    .home-page .testimonial_2 .slide-container {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .home-page .testimonial_2 .ant-card {
        max-width: 90%;
        padding: 0;
    }

    .home-page .testimonial_2 .ant-card img {
        height: 200px;
        margin-bottom: 20px
    }

    h2 {
        font-size: 26px;
        line-height: 32px
    }

    p {
        font-size: 16px;
    } */
}